import React from "react"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import "../styles/Features.css"
import "../styles/Pricing.css"
import circlepng from "../images/circlepng.png"
import { Helmet } from "react-helmet"
import { registerURL } from "../components/Const"
import starterIcon from "../images/starterIcon.svg"
import checkIcon from "../images/checkIcon.svg"
import noCheckIcon from "../images/noCheckIcon.svg"
import basicIcon from "../images/basicIcon.svg"
import mediumIcon from "../images/mediumIcon.svg"
import premiumIcon from "../images/premiumIcon.svg"
import enterpriseIcon from "../images/enterpriseIcon.svg"
import Features from "../components/Features"

export default function Pricing() {
  return (
    <div className="pricing-page-holder">
      <Helmet>
        <title>
          Pricing | ReAim - Reach your audience with push notifications
        </title>
      </Helmet>
      <Navigation />
      <div className="pricing-container">
        <div className="pricing-header pricing-header-align">
          <h1>Pricing Plans</h1>
          <h3>
            Plans for any kind of website, no matter size. No hidden fees.
            <br /> If this is not enough, shoot us an email and we will do
            custom setup.
          </h3>
        </div>
        <div className="pricing-cards-container">
          <div className="pricing-card pricing-card1">
            <img
              src={starterIcon}
              className="pricing-icon"
              alt="pricing icon"
            />

            <h1 className="pricing-h1-margin">Free</h1>
            <h3 className="pricing-h3-margin">
              New to using Push Notifications? Free plan includes everything you
              need to get started.
            </h3>
            <h1 className="pricing-h1-price">$0</h1>
            <div>
              <h3 className="pricing-span-month pricing-span-month-free">
                Billed Monthly
              </h3>
            </div>
            <div className="pricing-website-subscriber">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-h3-website">1 Website</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">1K Subscribers</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Messages</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={noCheckIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin pricing-no-check">
                Unlimited A/B tests
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={noCheckIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin pricing-no-check">
                Unlimited Audiences
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Triggers</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited RSS feeds
              </h3>
            </div>
            <div className="pricing-card-button-container">
              <a
                className="pricing-card-button"
                href={registerURL}
                alt="Try it for Free"
              >
                <span className="pricing-card-button-text">Get Started</span>
                <span className="pricing-card-arrow"></span>
              </a>
            </div>
          </div>

          <div className="pricing-card pricing-card2">
            <img src={basicIcon} className="pricing-icon" alt="pricing icon" />

            <h1 className="pricing-h1-margin">Basic</h1>
            <h3 className="pricing-h3-margin">
              Enough to please small to medium-sized businesses who want to get
              intimate with their audience.
            </h3>
            <h1 className="pricing-h1-price">$29</h1>
            <div>
              <h3 className="pricing-span-month">Billed Monthly</h3>
            </div>
            <div className="pricing-website-subscriber">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-h3-website">Up to 3 Websites</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">30K Subscribers</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Messages</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited A/B tests
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited Audiences
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Triggers</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited RSS feeds
              </h3>
            </div>
            <div className="pricing-card-button-container">
              <a
                className="pricing-card-button"
                href={registerURL}
                alt="Try it for Free"
              >
                <span className="pricing-card-button-text">Get Started</span>
                <span className="pricing-card-arrow"></span>
              </a>
            </div>
          </div>

          <div className="pricing-card pricing-card3">
            <div className="pricing-medium-cont">
              <img
                src={mediumIcon}
                className="pricing-icon"
                alt="pricing icon"
              />
              <span className="left-showcase-logo pricing-medium-popular">
                <span className="left-showcase-text pricing-medium-popular-text">
                  Most Popular
                </span>
              </span>
            </div>

            <h1 className="pricing-h1-margin">Enhanced</h1>
            <h3 className="pricing-h3-margin">
              Chosen by ambitious entrepreneurs who want to skyrocket their
              marketing efforts with Push Notifications.
            </h3>
            <h1 className="pricing-h1-price">$49</h1>
            <div>
              <h3 className="pricing-span-month">Billed Monthly</h3>
            </div>
            <div className="pricing-website-subscriber">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-h3-website">Up to 5 Websites</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">100K Subscribers</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Messages</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited A/B tests
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited Audiences
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Triggers</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited RSS feeds
              </h3>
            </div>
            <div className="pricing-card-button-container">
              <a
                className="pricing-card-button"
                href={registerURL}
                alt="Try it for Free"
              >
                <span className="pricing-card-button-text">Get Started</span>
                <span className="pricing-card-arrow"></span>
              </a>
            </div>
          </div>

          <div className="pricing-card pricing-card4">
            <img
              src={premiumIcon}
              className="pricing-icon"
              alt="pricing icon"
            />

            <h1 className="pricing-h1-margin">Premium</h1>
            <h3 className="pricing-h3-margin">
              Ideal solution for business owners who operate high-volume traffic
              sites and want to scale multiple websites.
            </h3>
            <h1 className="pricing-h1-price">$99</h1>
            <div>
              <h3 className="pricing-span-month">Billed Monthly</h3>
            </div>
            <div className="pricing-website-subscriber">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-h3-website">Unlimited Websites</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">300K Subscribers</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Messages</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited A/B tests
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited Audiences
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Triggers</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited RSS feeds
              </h3>
            </div>
            <div className="pricing-card-button-container">
              <a
                className="pricing-card-button"
                href={registerURL}
                alt="Try it for Free"
              >
                <span className="pricing-card-button-text">Get Started</span>
                <span className="pricing-card-arrow"></span>
              </a>
            </div>
          </div>

          <div className="pricing-card pricing-card5">
            <img
              src={enterpriseIcon}
              className="pricing-icon"
              alt="pricing icon"
            />

            <h1 className="pricing-h1-margin">Enterprise</h1>
            <h3 className="pricing-h3-margin">
              Tailored to business models that prefer setups according to needs;
              with dedicated servers, exclusive support, periodic marketing
              consultations, and more.
            </h3>
            <h1 className="pricing-h1-price">Custom</h1>
            <div>
              <h3 className="pricing-span-month">Reach out for a quote</h3>
            </div>
            <div className="pricing-website-subscriber">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-h3-website">Unlimited Websites</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited Subscribers
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Messages</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited A/B tests
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited Audiences
              </h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">Unlimited Triggers</h3>
            </div>
            <div className="pricing-website-subscriber2">
              <img
                src={checkIcon}
                className="pricing-checkIcon"
                alt="check icon"
              />
              <h3 className="pricing-subscribers-margin">
                Unlimited RSS feeds
              </h3>
            </div>
            <div className="pricing-card-button-container">
              <a
                className="pricing-card-button"
                href={registerURL}
                alt="Try it for Free"
              >
                <span className="pricing-card-button-text">
                  Get an Estimate
                </span>
                <span className="pricing-card-arrow pricing-card-arrow-custom"></span>
              </a>
            </div>
          </div>

          <div className="pricing-circle">
            <img src={circlepng} className="pricing-circle-img" alt="circle" />
          </div>
        </div>
      </div>
      <div className="circle-features-pricing2">
        <img src={circlepng} alt="circle" />
      </div>
      <div className="pricing-free-text">
        <p className="pricing-free-text-p">
          * Each plan contains 1000 free subscribers without credit card.
        </p>
      </div>
      <Features customStyle={{ backgroundColor: "white", boxShadow: "none" }} />
      <div className="circle-features-pricing">
        <img
          src={circlepng}
          className="circle-features-pricing-img"
          alt="circle"
        />
      </div>
      <section className="cta-title cta-title-pricing">
        <div className="cta-title-text cta-title-text-pricing">
          <h1>Don’t miss the opportunity!</h1>
          <p className="feature-p-padding">
            You now have 2 options. You can leave the site and miss perhaps the
            best opportunity to improve your business or completely free of
            charge without entering a credit card try a modern way of
            remarketing that has 4x better open rate than mail marketing. And
            which will allow you to reach your subscribers whether they are in
            the office, at a concert, dinner or chatting with the best friend.
          </p>
        </div>
        <section className="work-btn features-btn-fix">
          <a
            className="showcase-btn rwd-features-btn-size"
            href={registerURL}
            alt="Try it for Free"
          >
            <span className="showcase-btn-text">Sign Up</span>
            <span className="right-arrow"></span>
          </a>
          <h6 className="btn-credit-card btn-credit-card-work rwd-btn-credit-card-features">
            * No Credit Card Required
          </h6>
        </section>
      </section>
      <Footer />
    </div>
  )
}
